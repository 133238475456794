import { useState, useCallback, ChangeEvent } from 'react';
import { FormField, TypeFormHookResult, TypeFormConfig } from './types';

const DEFAULT_FORM_CONFIG = {
  [FormField.Name]: '',
  [FormField.Phone]: '',
  [FormField.Comment]: '',
  [FormField.Email]: '',
};

export function useForm(): TypeFormHookResult {
  const [formConfig, setFormConfig] =
    useState<TypeFormConfig>(DEFAULT_FORM_CONFIG);

  const handleChange = useCallback(
    ({ target: { name, value } }: ChangeEvent<HTMLInputElement>) => {
      if (!name) {
        return;
      }

      if (name === 'email') {
        setFormConfig((prevState) => ({ ...prevState, [name]: value.trim() }));

        return;
      }

      if (name !== 'phone') {
        setFormConfig((prevState) => ({ ...prevState, [name]: value }));

        return;
      }

      setFormConfig((prevState) => ({
        ...prevState,
        [name]: value.trim().replace(/\D/g, ''),
      }));
    },
    [],
  );

  return { formConfig, handleChange };
}
