import { FormEvent, useCallback, useState } from 'react';
import emailjs from '@emailjs/browser';
import { navigate } from 'gatsby';
import {
  TypeSubmitHookResult,
  TypeFieldsErrors,
  FormField,
  TypeFormConfig,
  TypeFieldsErrorsResult,
} from './types';

const DEFAULT_FIELD_ERRORS: TypeFieldsErrors = {
  [FormField.Name]: false,
  [FormField.Phone]: false,
  [FormField.Email]: false,
};

function getEmailError(phoneValue: string, emailValue: string): boolean {
  return (
    (phoneValue.length < 9 && !emailValue) ||
    (!!emailValue && (!emailValue.includes('@') || !emailValue.includes('.')))
  );
}

function getPhoneError(phoneValue: string, emailValue: string): boolean {
  if (phoneValue && phoneValue.length < 9) {
    return true;
  }

  if (phoneValue && phoneValue.length >= 9) {
    return false;
  }

  if (!emailValue && !phoneValue) {
    return true;
  }

  return (
    !!emailValue && (!emailValue.includes('@') || !emailValue.includes('.'))
  );
}

export function getFieldsErrors(
  formConfig: TypeFormConfig,
): TypeFieldsErrorsResult {
  const fieldsHasErrors: TypeFieldsErrors = {
    [FormField.Name]: !formConfig[FormField.Name],
    [FormField.Phone]: getPhoneError(
      formConfig[FormField.Phone],
      formConfig[FormField.Email],
    ),
    [FormField.Email]: getEmailError(
      formConfig[FormField.Phone],
      formConfig[FormField.Email],
    ),
  };

  const hasErrors = Object.values(fieldsHasErrors).some(
    (fieldError) => fieldError,
  );

  return { hasErrors, fieldsHasErrors };
}

export function useSubmit(formConfig: TypeFormConfig): TypeSubmitHookResult {
  const [fieldErrors, setFieldErrors] =
    useState<TypeFieldsErrors>(DEFAULT_FIELD_ERRORS);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleSubmit = useCallback(
    async (e: FormEvent | HTMLFormElement) => {
      e.preventDefault();
      const { hasErrors, fieldsHasErrors } = getFieldsErrors(formConfig);

      if (!hasErrors && !isLoading) {
        setFieldErrors(fieldsHasErrors);
        try {
          setIsLoading(true);

          await emailjs.sendForm(
            'service_lst9fn9',
            'template_tfbgiip',
            e.currentTarget,
            'lVmSE-RkV6LqnYSOa',
          );
          await navigate('/thankYouPage');
        } catch (err) {
          console.error(err);
        } finally {
          setIsLoading(false);
        }
      } else {
        setFieldErrors(fieldsHasErrors);
      }
    },
    [formConfig, isLoading],
  );

  return { fieldErrors, handleSubmit };
}
