// extracted by mini-css-extract-plugin
export var button = "WorkWithUs-module--button--wiIQY";
export var content = "WorkWithUs-module--content--8Rtvg";
export var field = "WorkWithUs-module--field--5L9lJ";
export var form = "WorkWithUs-module--form--952N6";
export var form__button = "WorkWithUs-module--form__button--Wr2RT";
export var form__fields = "WorkWithUs-module--form__fields--FMcl6";
export var form__fields__bottom = "WorkWithUs-module--form__fields__bottom--3P2Z-";
export var form__fields__upper = "WorkWithUs-module--form__fields__upper--koSMF";
export var heading = "WorkWithUs-module--heading--lQdsF";
export var policyAccept = "WorkWithUs-module--policyAccept--SK45+";
export var policyAccept_text = "WorkWithUs-module--policyAccept_text--QsnU-";
export var title = "WorkWithUs-module--title--4xXcv";
export var title__label = "WorkWithUs-module--title__label--uAueE";
export var title__text = "WorkWithUs-module--title__text--vM4cS";
export var titles = "WorkWithUs-module--titles--XylEX";
export var titles__text = "WorkWithUs-module--titles__text--EWika";
export var titles__text_ideal = "WorkWithUs-module--titles__text_ideal--tBLXZ";
export var wrapper = "WorkWithUs-module--wrapper--JczrD";