import React, { memo, useState } from 'react';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { Button, Link, TextField, Label } from 'modules';
import { useWindowSize } from 'hooks';
import { useForm } from './useForm';
import { useSubmit } from './useSubmit';
import { FormField } from './types';
import * as styles from './WorkWithUs.module.scss';

function WorkWithUs(): JSX.Element {
  const [accept, setAccept] = useState<boolean>(false);
  const { t } = useTranslation();
  const { formConfig, handleChange } = useForm();
  const { fieldErrors, handleSubmit } = useSubmit(formConfig);

  const { width } = useWindowSize();

  function getAccept() {
    setAccept((prevState) => !prevState);
    console.log(accept);
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.content}>
        <div className={styles.title}>
          <Label className={styles.heading}>{t('workWithUs:label')}</Label>
          <span className={styles.title__text}>
            {t('workWithUs:title.create')}
          </span>
        </div>
        <div className={styles.titles}>
          <h6 className={styles.titles__text_ideal}>
            {t('workWithUs:title.ideal')}
          </h6>
          <h6 className={styles.titles__text}>
            {t('workWithUs:title.project')}
          </h6>
        </div>
        <form onSubmit={handleSubmit} className={styles.form}>
          <div className={styles.form__fields}>
            <div className={styles.form__fields__upper}>
              <TextField
                className={styles.field}
                name="name"
                placeholder={t('workWithUs:form.name.placeholder')}
                value={formConfig[FormField.Name]}
                hasError={fieldErrors[FormField.Name]}
                onChange={handleChange}
              />
              <TextField
                className={styles.field}
                name="phone"
                placeholder={t('workWithUs:form.phone.placeholder')}
                value={formConfig[FormField.Phone]}
                hasError={fieldErrors[FormField.Phone]}
                onChange={handleChange}
              />
            </div>
            <TextField
              className={styles.field}
              name="email"
              placeholder={t('workWithUs:form.email.placeholder')}
              value={formConfig[FormField.Email]}
              hasError={fieldErrors[FormField.Email]}
              onChange={handleChange}
            />
            <div className={styles.form__fields__bottom}>
              <TextField
                className={styles.field}
                name="comment"
                placeholder={t('workWithUs:form.comment.placeholder')}
                value={formConfig[FormField.Comment]}
                onChange={handleChange}
              />
              <div className={styles.policyAccept}>
                <input checked={accept} type="radio" onClick={getAccept} />
                <label className={styles.policyAccept_text}>
                  {t('workWithUs:acceptance')}
                  <Link
                    isUppercase={false}
                    className={styles.policyAccept_text}
                    theme="white"
                    to="/privacy"
                  >
                    {t('workWithUs:policy')}
                  </Link>
                </label>
              </div>
            </div>
          </div>
          <div className={styles.form__button}>
            <Button
              className={styles.button}
              size={width > 360 ? 'l' : 'm'}
              theme="white"
              hasArrow
              type="submit"
              isDisabled={!accept}
            >
              {t('workWithUs:button')}
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default memo(WorkWithUs);
